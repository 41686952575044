<template>
  <!-- 2期新增 行程会议 wwl -->
  <div class="webMainEntry">
    <nav-view :titleName="myJoin.type_name"></nav-view>
    <div style="width: 100%; background: #f7f8fa; padding-bottom: 25px">
      <!--  style="min-height:1222px;" -->
      <div
        class="centerWidth marginAuto"
        v-if="callBackList[showIndex] && callBackList[showIndex].length > 0"
      >
        <!-- 讲师列表组件 -->
        <teacher-list
          v-if="myJoin.type == 'teacher'"
          :theacherList="callBackList[showIndex]"
        ></teacher-list>
        <industry
          v-else-if="myJoin.type == 'industry_Activities'"
          :list="callBackList[showIndex]"
        ></industry>
        <meeting-schedule
          v-else-if="myJoin.type == 'meeting_schedule'"
          :list="callBackList[showIndex]"
        ></meeting-schedule>
        <div style="display: flex; justify-content: flex-start" v-else>
          <!-- 搜索修改，添加讲师 -->
          <course-list
            :courseList="callBackList[showIndex]"
            :showType="1"
            :showNumber="4"
            :iSteacher='true'
          >
          </course-list>
        </div>
        <!-- <router-view></router-view> -->
        <el-pagination
          style="margin-top: 37px"
          background
          layout="prev, pager, next"
          :total="dataCount"
          :page-size="12"
          :current-page = 'page'
          @current-change="currentChange"
        >
        </el-pagination>
      </div>
      <temp-data v-else></temp-data>
    </div>
  </div>
</template>
<script>
import {
  type_list,
  banner,
  lecturer_list,
  hot_course,
  home_type_list,
  course_by_type,
  course_home_type,
  advert_list,
  course_search
} from '@/utils/Api/homeList'
import {
  MyCourse_like,
  MyMy_plan,
  course_like_NoToken
} from '@/utils/Api/userList'
import { get_meeting_list, get_schedule_list_pc } from '@/utils/Api/meeting'
import teacherList from '@/components/teacherListmore'
import courseList from '@/components/courseList'
import navView from '../../../components/navView.vue'
import industry from '@/components/Industry.vue'
import tempData from '@/components/tempData'
import meetingSchedule from '@/components/meetingSchedule.vue'
export default {
  name: 'webMainEntry',
  components: {
    teacherList,
    courseList,
    navView,
    tempData,
    industry,
    meetingSchedule
  },
  data() {
    return {
      navTitle: '讲师团',
      callBackList: [],
      dataCount: 0,
      showIndex: 0,
      myJoin: {},
      page: 1
    }
  },
  created() {
    console.log(this.$route.query, '$$$$$$$$$$')
    this.myJoin = this.$route.query
    if (this.$route.query.type == 'search') {
      this.myJoin.type_name = '搜索'
    }
    this.$route.meta.title = this.myJoin.type_name
    document.title = this.$route.meta.title
    this.initial()
  },
  computed: {
    watchType() {
      return this.$route.query.type
    },
    watchWords() {
      return this.$route.query.words
    }
  },
  watch: {
    watchType() {
      // console.log(this.watchType)
      this.$router.go(0)
    },
    watchWords() {
      // console.log(this.watchWords)
      this.$router.go(0)
    }
  },
  methods: {
    async initial() {
      console.log(this.myJoin)
      if (this.myJoin.type == 'teacher') {
        let callBackList = await lecturer_list()
        for (let i = 0; i < callBackList.length; i += 15) {
          this.callBackList.push(callBackList.slice(i, i + 15))
        }
        this.dataCount = callBackList.length
      }
      if (this.myJoin.type == 'hotCourse') {
        this.hot_course()
      }
      if (this.myJoin.type == 'guessLike') {
        this.guessLike()
      }
      if (this.myJoin.type == 'callback') {
        // console.log('callback12312')
        this.callbackData()
      }
      if (this.myJoin.type == 'search') {
        this.searchData()
      }
      if (this.myJoin.type == 'industry_Activities') {
        this.get_industry_Activities()
      }
      if (this.myJoin.type == 'meeting_schedule') {
        this.get_meeting_schedule()
      }
    },
    async get_industry_Activities() {
      let { total, data } = await get_meeting_list({
        limit: 12,
        page: this.page
      })
      for (let i = 0; i < Math.ceil(total / 12); i++) {
        this.callBackList.push([])
      }
      data
        ? (this.callBackList[this.page - 1] = data)
        : this.$message.error(this.myJoin.type_name + '获取失败')
      this.dataCount = total
    },
    async get_meeting_schedule() {
      let { total, data } = await get_schedule_list_pc({
        limit: 12,
        page: this.page
      })
      var res = []
      for (let i = 0; i < Math.ceil(total / 12); i++) {
        this.callBackList.push([])
      }
      if (data && Object.keys(data).length > 0) {
        for (let key in data) {
          res.push({ key: key, data: data[key] })
        }
      }
      res
        ? (this.callBackList[this.page - 1] = JSON.parse(JSON.stringify(res)))
        : this.$message.error(this.myJoin.type_name + '获取失败')
      this.dataCount = total
    },
    async searchData() {
      let params = {
        word: this.$route.query.words,
        page: this.page,
        limit: 12
      }
      let { res, count } = await course_search(params)
      this.dataCount = count

      for (let i = 0; i < Math.ceil(count / 12); i++) {
        this.callBackList.push([])
      }

      res
        ? (this.callBackList[this.page - 1] = res)
        : this.$message.warning(this.myJoin.type_name + '获取失败')
      // res ? this.callBackList.push(res) : this.$message.error('搜索结果为空')
    },
    async callbackData() {
      // console.log(111)

      let params = {
        home_type_id: this.myJoin.type_id,
        page: this.page,
        limit: 12
      }

      let { res, count } = await course_home_type(params)
      // console.log(res, count)

      for (let i = 0; i < Math.ceil(count / 12); i++) {
        this.callBackList.push([])
      }

      this.dataCount = count
      res
        ? (this.callBackList[this.page - 1] = res)
        : this.$message.error(this.myJoin.type_name + '获取失败')
    },
    async hot_course() {
      let params = {
        type: 1,
        page: this.page,
        limit: 12
      }
      const { res, count } = await hot_course(params)
      // console.log((await hot_course(params)))

      for (let i = 0; i < Math.ceil(count / 12); i++) {
        this.callBackList.push([])
      }

      this.dataCount = count
      res
        ? (this.callBackList[this.page - 1] = res)
        : this.$message.error('热门课程获取失败')
    },
    async guessLike() {
      let params = {
        page: this.page,
        limit: 12
      }
      let a, res, count
      if (this.$store.getters.token) {
        a = await MyCourse_like(params)
        res = a.res
        count = a.count
      } else {
        a = await course_like_NoToken(params)
        res = a.res
        count = a.count
      }

      for (let i = 0; i < Math.ceil(count / 12); i++) {
        this.callBackList.push([])
      }

      this.dataCount = count
      res
        ? (this.callBackList[this.page - 1] = res)
        : this.$message.error('猜你喜欢获取失败')
    },
    currentChange(value) {
      if (this.myJoin.type != 'teacher') {
        this.page = value
        this.initial()
      }
      this.showIndex = value - 1
      this.scrollTop()
    }
  }
}
</script>
<style lang="scss" scoped>
.webMainEntry {
  .navigatList {
    width: 100%;
    background: #47d7e3;
    height: 67px;
    color: #fff;
    .navTitle {
      font-size: 24px;
    }
  }
  .navListName {
    margin-right: 8px;
    margin-left: 8px;
  }
}
</style>