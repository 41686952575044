<template>
  <div class="list_box">
    <!-- 参会行程列表 列表 -->
    <div class="row_box"
         v-for="(row,i) in list"
         :key='i'>
      <div class="time">{{row.key}}</div>
      <div class="row_list">
        <div class="row"
             v-for="(it,index) in row.data"
             @click="goother({path:'/personal/meetingOrderDetail', query:{orderId:it.id}})"
             :key='index'>
          <div class="left">
            <img :src="$global.regexImg(it.meet.logo_web)"
                 alt="">
          </div>
          <div class="right">
            <div class='title'>{{it.meet.meet_name}}</div>
            <div class=''>{{it.meet.start_date}} - {{it.meet.end_date}}</div>
            <div class="ellispOne">
              <img src="../assets/img/meeting/icon_02.png"
                   alt="">{{it.meet.address}}
            </div>
            <div class="ellispOne">
              <img src="../assets/img/meeting/icon_22.png"
                   alt="">费用{{it.amount}}
            </div>
            <div :class="it.pay_status == 1 ?'active':''">
              <img :src="require('../assets/img/meeting/icon_01.png')"
                   v-if='it.pay_type == 2'
                   mode="">
              <img src="../assets/img/meeting/icon_05.png"
                   v-else-if='it.pay_type == 1'
                   mode="">
              <img src="../assets/img/meeting/icon_04.png"
                   v-else-if='it.pay_type == 3'
                   mode="">
              <img src="../assets/img/meeting/icon_06.png"
                   v-else-if='it.pay_type == 4'
                   mode="">
              <img src="../assets/img/meeting/mianfei.png"
                   v-else-if='it.pay_type == 5'
                   mode="">
              <span>{{payList[it.pay_type-1]}}<template v-if='it.pay_type != 5'>：{{it.pay_status == 1 ?'已支付' : it.pay_status == 2 ?'未支付' :'已取消'}}</template></span>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'meeting-schedule',
  components: {

  },
  props: {
    list: {
      type: Array,
      default: []
    }
  },
  data () {
    return {
      payList: ['支付宝', '微信', '现场支付', '线下支付', '免费'],
    }
  },
  methods: {},
  created () { },
  mounted () { },
  destroyed () { },
  computed: {},
  watch: {}
}
</script>

<style lang="scss" scoped>
.list_box {
  .row_box {
    margin-bottom: 50px;
  }
  .time {
    font-size: 18px;
    font-family: DIN Condensed, DIN Condensed-Bold;
    font-weight: 700;
    color: #333333;
    padding: 30px 0 16px;
    text-align: left;
  }
  .row_list {
    display: flex;
    flex-wrap: wrap;
  }
  .row {
    cursor: pointer;
    &:nth-child(even) {
      margin-right: 0 !important;
    }
    width: 595px;
    height: 181px;
    background: #ffffff;
    border-radius: 0px 5px 5px 0px;
    margin-right: 10px;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
  }
  .left {
    width: 287px;
    height: 181px;
    display: flex;
    overflow: hidden;
    img {
      &:hover {
        transform: scale(1.1);
        transition: all 0.3s;
      }
      max-width: 287px;
      max-height: 181px;
    }
  }
  .right {
    width: calc(100% - 320px);
    padding-right: 20px;
    padding-top: 14px;
    box-sizing: border-box;
    .active {
      color: #1dcad3;
    }
    > div {
      margin-bottom: 10px;
      font-size: 14px;
      font-family: PingFang HK, PingFang HK-Regular;
      font-weight: 400;
      text-align: left;
      color: #999999;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: middle;
      img {
        max-width: 15px;
        max-height: 15px;
        margin-right: 10px;
        vertical-align: middle;
      }
    }
    .title {
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      margin-bottom: 20px;
      color: #333;
    }
  }
}
</style>
